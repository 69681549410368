import { SVGProps } from "react";

export function PostgreSqlIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_7_30)">
          <g clipPath="url(#clip1_7_30)">
            <path
              fill="#000"
              d="M47.458 46.59c.407-3.393.285-3.888 2.81-3.34l.642.057c1.943.088 4.483-.312 6-1.005 3.213-1.49 5.118-3.98 1.95-3.325-7.225 1.49-7.75-.957-7.75-.957 7.633-11.325 10.825-25.7 8.068-29.217-7.51-9.6-20.513-5.06-20.73-4.94l-.07.012c-1.425-.3-3-.475-4.825-.5-3.27-.05-5.75.858-7.635 2.285 0 0-23.193-9.555-22.116 12.017.23 4.59 6.575 34.75 14.15 25.626 2.773-3.335 5.448-6.153 5.448-6.153 1.325.883 2.918 1.333 4.585 1.17l.13-.11c-.038.432-.02.867.053 1.295-1.95 2.18-1.375 2.563-5.276 3.365-3.944.813-1.625 2.26-.114 2.64 1.835.46 6.08 1.11 8.95-2.905l-.116.458c.765.612.713 4.4.826 7.104.112 2.706.29 5.233.842 6.72.553 1.488 1.2 5.325 6.325 4.25 4.283-.917 7.558-2.24 7.858-14.525"
            ></path>
            <path
              stroke="#000"
              strokeWidth="5.372"
              d="M47.458 46.59c.407-3.393.285-3.888 2.81-3.34l.642.057c1.943.088 4.483-.312 6-1.005 3.213-1.49 5.118-3.98 1.95-3.325-7.225 1.49-7.75-.957-7.75-.957 7.633-11.325 10.825-25.7 8.068-29.217-7.51-9.6-20.513-5.06-20.73-4.94l-.07.012c-1.425-.3-3-.475-4.825-.5-3.27-.05-5.75.858-7.635 2.285 0 0-23.193-9.555-22.116 12.017.23 4.59 6.575 34.75 14.15 25.626 2.773-3.335 5.448-6.153 5.448-6.153 1.325.883 2.918 1.333 4.585 1.17l.13-.11c-.038.432-.02.867.053 1.295-1.95 2.18-1.375 2.563-5.276 3.365-3.944.813-1.625 2.26-.114 2.64 1.835.46 6.08 1.11 8.95-2.905l-.116.458c.765.612.713 4.4.826 7.104.112 2.706.29 5.233.842 6.72.553 1.488 1.2 5.325 6.325 4.25 4.283-.917 7.558-2.24 7.858-14.525"
            ></path>
            <path
              fill="#336791"
              d="M58.837 39c-7.225 1.49-7.75-.958-7.75-.958 7.633-11.325 10.825-25.7 8.07-29.217-7.51-9.6-20.512-5.058-20.73-4.94l-.07.012a25.774 25.774 0 00-4.822-.502c-3.27-.05-5.75.857-7.635 2.285 0 0-23.195-9.555-22.115 12.018.23 4.59 6.575 34.75 14.15 25.624A141.326 141.326 0 0123.4 37.15c1.325.883 2.918 1.333 4.585 1.17l.13-.11c-.038.432-.02.867.053 1.295-1.95 2.18-1.375 2.563-5.276 3.365-3.944.813-1.625 2.26-.114 2.64 1.835.46 6.08 1.11 8.95-2.905l-.116.458c.765.612 1.3 3.982 1.21 7.037-.09 3.055-.15 5.15.45 6.79.6 1.64 1.2 5.325 6.325 4.25 4.283-.918 6.5-3.3 6.813-7.265.22-2.82.715-2.405.75-4.925l.4-1.195c.457-3.825.075-5.058 2.712-4.483l.643.058c1.943.087 4.485-.313 5.975-1.005 3.212-1.49 5.115-3.98 1.947-3.325z"
            ></path>
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.79"
              d="M32.035 41.167c-.2 7.115.05 14.28.745 16 .695 1.72 2.188 5.125 7.315 4.03 4.282-.917 5.843-2.695 6.517-6.617l1.583-12.542M25.89 5.5S2.68-3.99 3.76 17.582c.23 4.59 6.575 34.75 14.15 25.626 2.765-3.333 5.265-5.94 5.265-5.94m15.25-33.5c-.8.25 12.91-5.013 20.705 4.945 2.75 3.517-.438 17.892-8.07 29.217"
            ></path>
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="bevel"
              strokeWidth="1.79"
              d="M51.063 37.925s.5 2.45 7.75.955c3.167-.655 1.26 1.835-1.95 3.325-2.636 1.225-8.545 1.538-8.643-.15-.25-4.362 3.11-3.038 2.867-4.13-.22-.985-1.725-1.95-2.715-4.36-.867-2.1-11.9-18.225 3.06-15.832.55-.113-3.9-14.25-17.9-14.456-14-.205-13.557 17.213-13.557 17.213"
            ></path>
            <path
              stroke="#fff"
              strokeLinejoin="round"
              strokeWidth="1.79"
              d="M28.117 39.42c-1.95 2.18-1.375 2.563-5.274 3.365-3.945.813-1.625 2.26-.116 2.64 1.835.46 6.08 1.11 8.95-2.907.876-1.226-.005-3.175-1.204-3.67-.58-.24-1.355-.54-2.35.575l-.006-.003z"
            ></path>
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.79"
              d="M27.99 39.383c-.2-1.283.42-2.806 1.083-4.59.994-2.675 3.29-5.35 1.454-13.843-1.367-6.325-10.55-1.317-10.55-.46 0 .858.415 4.35-.15 8.413-.742 5.305 3.375 9.79 8.115 9.332"
            ></path>
            <path
              fill="#fff"
              stroke="#fff"
              strokeWidth="0.598"
              d="M25.805 20.363c-.043.292.538 1.075 1.29 1.18.753.105 1.395-.505 1.438-.8.042-.295-.538-.615-1.29-.72-.753-.105-1.4.05-1.438.34z"
            ></path>
            <path
              fill="#fff"
              stroke="#fff"
              strokeWidth="0.297"
              d="M48.715 19.765c.04.293-.538 1.075-1.29 1.18-.752.105-1.4-.505-1.438-.8-.037-.295.538-.615 1.29-.72.753-.105 1.4.05 1.438.34z"
            ></path>
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.79"
              d="M51.405 17.738c.125 2.3-.495 3.862-.575 6.31-.115 3.555 1.695 7.625-1.032 11.7"
            ></path>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_7_30">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
          <clipPath id="clip1_7_30">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }