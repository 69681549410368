import { SVGProps } from "react";

export function GitIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_7_66)">
          <mask
            id="mask0_7_66"
            style={{ maskType: "luminance" }}
            width="65"
            height="64"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
          >
            <path fill="#fff" d="M0 0h64v64H0V0z"></path>
          </mask>
          <g mask="url(#mask0_7_66)">
            <path
              fill="#F03C2E"
              d="M62.795 29.15L34.85 1.208a4.122 4.122 0 00-5.831 0l-5.8 5.803 7.359 7.36a4.897 4.897 0 016.198 6.241l7.095 7.093a4.896 4.896 0 016.133 2.756 4.902 4.902 0 01-4.534 6.78 4.91 4.91 0 01-3.47-1.436 4.907 4.907 0 01-1.064-5.335l-6.617-6.615v17.41a4.903 4.903 0 11-5.64.928 4.917 4.917 0 011.605-1.072v-17.57a4.908 4.908 0 01-2.66-6.432l-7.257-7.257L1.206 29.019a4.127 4.127 0 000 5.83L29.15 62.795a4.126 4.126 0 005.83 0L62.795 34.98a4.127 4.127 0 000-5.83z"
            ></path>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_7_66">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }