import React from 'react';
import './Home.scss';
import Avatar from '../images/avatar.jpg';
import { WorkIcon } from '../images/WorkIcon';
import { BelgiumFlag } from '../images/BelgiumFlag';
import { CSharpIcon } from '../images/skills/CSharpIcon';
import { TypeScriptIcon } from '../images/skills/TypeScriptIcon';
import { NestJSIcon } from '../images/skills/NestJSIcon';
import { MySQLIcon } from '../images/skills/MySQLIcon';
import { PostgreSqlIcon } from '../images/skills/PostgreSqlIcon';
import { GitIcon } from '../images/skills/GitIcon';
import { DockerIcon } from '../images/skills/DockerIcon';
import { ReactIcon } from '../images/skills/ReactIcon';
import { SourceEngineIcon } from '../images/skills/SourceEngineIcon';
import { PHPIcon } from '../images/skills/PHPIcon';
import { BlazorIcon } from '../images/skills/BlazorIcon';
import { AngularIcon } from '../images/skills/AngularIcon';
import { FigmaIcon } from '../images/skills/FigmaIcon';
import { DotNetIcon } from '../images/skills/DotNetIcon';
import Socials from '../components/Socials';
import { LanguageIcon } from '../images/LanguageIcon';
import { AdobeIcon } from '../images/skills/AdobeIcon';
import { VBNetIcon } from '../images/skills/VBNetIcon';
import { PostmanIcon } from '../images/skills/PostmanIcon';
import { BlenderIcon } from '../images/skills/BlenderIcon';
import { SboxIcon } from '../images/skills/SboxIcon';
import Repositories from '../components/Repositories';
import Footer from '../components/Footer';
import GoTop from '../components/GoTop';


function Home() {
    return (
        <div className="Home">
            <header id="profil">
                <div className="me">
                    <img src={Avatar} alt="Benjamin Talpa" width="156"/>
                    <span className="name">Benjamin Talpa</span>
                </div>
                <div className="description">
                    <div className="left">
                        <div className="info">
                            <WorkIcon/>Full-stack Developer
                        </div>
                        <div className="info">
                            <BelgiumFlag/>Brussels, Belgium
                        </div>
                        <div className="about">
                            I'm a passionate young, talended and self taught full stack developer.
                            <br/>
                            I've had the opportunity to work on many different technologies and acquire the knowledge of them.
                        </div>
                    </div>
                    <div className="right">
                        <div className="info">French <LanguageIcon/></div>
                        <div className="info">Romanian <LanguageIcon/></div>
                        <div className="info">English <LanguageIcon/></div>
                    </div>
                </div>
                <Socials></Socials>
            </header>
            <div className="card" id="skills">
                <div className="title">Skills</div>

                <div className="container">
                    <div className="skill">
                        <CSharpIcon/>
                        C# 
                    </div>
                    <div className="skill">
                        <TypeScriptIcon/>
                        TypeScript
                    </div>
                    <div className="skill">
                        <NestJSIcon/>
                        Nest.js
                    </div>
                    <div className="skill">
                        <MySQLIcon/>
                        MySQL
                    </div>
                    <div className="skill">
                        <PostgreSqlIcon/>
                        PostgreSQL
                    </div>
                    <div className="skill">
                        <GitIcon/>
                        Git
                    </div>
                    <div className="skill">
                        <DockerIcon/>
                        Docker
                    </div>
                    <div className="skill">
                        <ReactIcon/>
                        React.js
                    </div>
                    <div className="skill">
                        <ReactIcon/>
                        React Native
                    </div>
                    <div className="skill">
                        <SourceEngineIcon/>
                        Source Engine 1
                    </div>
                    <div className="skill">
                        <PHPIcon/>
                        PHP
                    </div>
                    <div className="skill">
                        <BlazorIcon/>
                        Blazor
                    </div>
                    <div className="skill">
                        <AngularIcon/>
                        Angular
                    </div>
                    <div className="skill">
                        <FigmaIcon/>
                        Figma
                    </div>
                    <div className="skill">
                        <DotNetIcon/>
                        .NET
                    </div>
                    <div className="skill">
                        <AdobeIcon/>
                        Adobe
                    </div>
                    <div className="skill">
                        <VBNetIcon/>
                        VB.Net
                    </div>
                    <div className="skill">
                        <PostmanIcon/>
                        Postman
                    </div>
                    <div className="skill">
                        <BlenderIcon/>
                        Blender
                    </div>
                    <div className="skill">
                        <SboxIcon/>
                        S&Box
                    </div>
                </div>
            </div>
            <div className="card" id="projects">
                <div className="title">Projects</div>

                <div className="container">
                    <div className="project">
                        <div className="project__name">
                            <span>Euro Community</span>
                            <span>Depuis 2022</span>
                        </div>
                        <hr/>
                        <div className="project__description">
                            Conception d'un mode de jeu multijoueur sur le jeu S&Box.
                            <br/>
                            Technologies utilisées:
                            <ul>
                                <li>Angular, React.js, Next.js</li>
                                <li>.NET 7.0</li>
                                <li>Adobe Photoshop</li>
                                <li>Figma</li>
                                <li>Blender</li>
                                <li>Razor</li>
                                <li>MySQL & PostgreSQL</li>
                                <li>Docker</li>
                                <li>Postman</li>
                                <li>HL Shader</li>
                                <li>Hammer Editor 2</li>
                            </ul>
                        </div>
                    </div>
                    <div className="project">
                        <div className="project__name">
                            <span>Enemy-Down</span>
                            <span>Depuis 2020</span>
                        </div>
                        <hr/>
                        <div className="project__description">
                            Conception d'un mode de jeu multijoueur sur le jeu S&Box.
                            <br/>
                            Technologies utilisées:
                            <ul>
                                <li>Angular</li>
                                <li>.NET 6.0</li>
                                <li>Adobe Photoshop</li>
                                <li>MySQL</li>
                                <li>Blender</li>
                                <li>Hammer Editor</li>
                            </ul>
                        </div>
                    </div>
                </div>
           </div>
           <Repositories></Repositories>
           <Footer></Footer>
           <GoTop></GoTop>
        </div>
    );
}

export default Home;