import { SVGProps } from "react";

export function ReactIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_7_100)">
          <path
            fill="#61DAFB"
            d="M32 38.01c3.15 0 5.704-2.467 5.704-5.51 0-3.043-2.554-5.51-5.704-5.51s-5.704 2.467-5.704 5.51c0 3.043 2.554 5.51 5.704 5.51z"
          ></path>
          <path
            stroke="#61DAFB"
            d="M32 43.788c16.905 0 30.609-5.054 30.609-11.288S48.905 21.212 32 21.212 1.391 26.266 1.391 32.5 15.095 43.788 32 43.788z"
          ></path>
          <path
            stroke="#61DAFB"
            d="M21.879 38.144c8.452 14.14 19.835 23.077 25.425 19.96 5.59-3.117 3.27-17.108-5.183-31.248-8.452-14.14-19.836-23.077-25.425-19.96-5.59 3.117-3.27 17.107 5.183 31.248z"
          ></path>
          <path
            stroke="#61DAFB"
            d="M21.879 26.856c-8.453 14.14-10.773 28.13-5.183 31.248 5.59 3.117 16.973-5.82 25.425-19.96 8.453-14.14 10.773-28.13 5.183-31.248-5.59-3.117-16.973 5.82-25.425 19.96z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_7_100">
            <path fill="#fff" d="M0 0H64V55H0z" transform="translate(0 5)"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }