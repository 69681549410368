import { SVGProps } from "react";

export function FigmaIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_9_218)">
          <path
            fill="#0ACF83"
            d="M21.752 64C27.685 64 32.5 59.221 32.5 53.333V42.667H21.752c-5.933 0-10.748 4.778-10.748 10.666C11.004 59.221 15.82 64 21.752 64z"
          ></path>
          <path
            fill="#A259FF"
            d="M11.004 32c0-5.888 4.815-10.667 10.748-10.667H32.5v21.334H21.752c-5.933 0-10.748-4.779-10.748-10.667z"
          ></path>
          <path
            fill="#F24E1E"
            d="M11.004 10.667C11.004 4.779 15.82 0 21.752 0H32.5v21.333H21.752c-5.933 0-10.748-4.778-10.748-10.666z"
          ></path>
          <path
            fill="#FF7262"
            d="M32.5 0h10.748c5.933 0 10.748 4.779 10.748 10.667S49.18 21.333 43.248 21.333H32.5V0z"
          ></path>
          <path
            fill="#1ABCFE"
            d="M53.996 32c0 5.888-4.815 10.667-10.748 10.667C37.315 42.667 32.5 37.888 32.5 32s4.815-10.667 10.748-10.667c5.933 0 10.748 4.779 10.748 10.667z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_9_218">
            <path fill="#fff" d="M0 0H43V64H0z" transform="translate(11)"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }