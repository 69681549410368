import { SVGProps } from "react";

export function Linkedin(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_12_244)">
          <g clipPath="url(#clip1_12_244)">
            <path
              fill="#3273EB"
              d="M50.667 0H13.333C5.971 0 0 5.97 0 13.333v37.334C0 58.029 5.97 64 13.333 64h37.334C58.032 64 64 58.03 64 50.667V13.333C64 5.971 58.032 0 50.667 0zM21.333 50.667h-8V21.333h8v29.334zm-4-32.715c-2.576 0-4.666-2.107-4.666-4.704 0-2.597 2.09-4.704 4.666-4.704 2.576 0 4.667 2.107 4.667 4.704 0 2.597-2.088 4.704-4.667 4.704zm36 32.715h-8V35.723c0-8.982-10.666-8.302-10.666 0v14.944h-8V21.333h8v4.707c3.722-6.896 18.666-7.405 18.666 6.603v18.024z"
            ></path>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_12_244">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
          <clipPath id="clip1_12_244">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }