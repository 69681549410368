import { SVGProps } from "react";

export function PHPIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_7_128)">
          <mask
            id="mask0_7_128"
            style={{ maskType: "luminance" }}
            width="64"
            height="36"
            x="0"
            y="14"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M.9 32c0 9.423 13.924 17.062 31.1 17.062 17.176 0 31.1-7.64 31.1-17.062 0-9.423-13.924-17.062-31.1-17.062C14.824 14.938.9 22.578.9 32z"
            ></path>
          </mask>
          <g mask="url(#mask0_7_128)">
            <path
              fill="url(#paint0_radial_7_128)"
              d="M.9 32c0 9.423 13.924 17.062 31.1 17.062 17.176 0 31.1-7.64 31.1-17.062 0-9.423-13.924-17.062-31.1-17.062C14.824 14.938.9 22.578.9 32z"
            ></path>
          </g>
          <mask
            id="mask1_7_128"
            style={{ maskType: "luminance" }}
            width="66"
            height="40"
            x="-1"
            y="12"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M-.396 12.996h64.792v38.008H-.396V12.996z"
            ></path>
          </mask>
          <g mask="url(#mask1_7_128)">
            <path
              fill="#777BB3"
              d="M32 47.795c16.505 0 29.885-7.072 29.885-15.795 0-8.723-13.38-15.795-29.885-15.795-16.505 0-29.885 7.072-29.885 15.795 0 8.723 13.38 15.795 29.885 15.795z"
            ></path>
          </g>
          <mask
            id="mask2_7_128"
            style={{ maskType: "luminance" }}
            width="66"
            height="40"
            x="-1"
            y="12"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M-.396 12.996h64.792v38.008H-.396V12.996z"
            ></path>
          </mask>
          <g mask="url(#mask2_7_128)">
            <path
              fill="#000"
              d="M17.797 33.958c1.357 0 2.37-.26 3.011-.775.634-.51 1.073-1.392 1.302-2.622.214-1.15.133-1.954-.242-2.387-.383-.443-1.212-.668-2.463-.668h-2.169l-1.202 6.452h1.763zm-7.094 7.948a.332.332 0 01-.26-.128.363.363 0 01-.071-.29l3.187-17.1a.342.342 0 01.33-.284h6.869c2.158 0 3.764.611 4.774 1.817 1.015 1.212 1.329 2.906.932 5.035a8.263 8.263 0 01-.826 2.394 7.974 7.974 0 01-1.52 1.986c-.744.727-1.586 1.254-2.5 1.563-.901.306-2.057.461-3.437.461H15.4l-.794 4.261a.341.341 0 01-.331.285h-3.572z"
            ></path>
            <path
              fill="#fff"
              d="M17.514 27.858h1.891c1.51 0 2.034.346 2.212.552.296.341.352 1.062.162 2.084-.214 1.143-.61 1.955-1.177 2.41-.58.466-1.524.702-2.805.702h-1.354l1.071-5.748zm3.244-4.106H13.89c-.323 0-.601.24-.663.57L10.041 41.42a.726.726 0 00.141.582c.129.162.32.256.521.256h3.572c.323 0 .6-.239.662-.57l.741-3.976h2.503c1.415 0 2.607-.16 3.54-.478.962-.326 1.846-.878 2.628-1.64a8.326 8.326 0 001.584-2.072 8.624 8.624 0 00.862-2.499c.418-2.241.079-4.036-1.009-5.334-1.076-1.285-2.768-1.937-5.029-1.937zM15.625 34.31h2.172c1.44 0 2.512-.283 3.217-.848.705-.566 1.18-1.51 1.427-2.834.237-1.271.129-2.168-.323-2.69-.452-.522-1.356-.784-2.713-.784h-2.447l-1.333 7.156zm5.133-9.854c2.066 0 3.573.566 4.52 1.697.947 1.13 1.233 2.71.855 4.736a7.871 7.871 0 01-.79 2.29 7.594 7.594 0 01-1.453 1.898c-.713.696-1.505 1.192-2.377 1.488-.872.296-1.982.443-3.332.443h-3.06l-.846 4.546h-3.572l3.187-17.098h6.867z"
            ></path>
            <path
              fill="#000"
              d="M34.653 37.36a.332.332 0 01-.26-.128.363.363 0 01-.072-.29l1.41-7.566c.134-.72.101-1.236-.093-1.454-.119-.133-.476-.357-1.53-.357h-2.554l-1.772 9.51a.341.341 0 01-.332.285h-3.543a.332.332 0 01-.26-.128.363.363 0 01-.071-.29l3.186-17.099a.341.341 0 01.331-.285h3.544c.1 0 .196.047.26.128.064.082.09.188.071.291l-.769 4.127h2.747c2.093 0 3.512.385 4.339 1.177.842.807 1.105 2.098.781 3.838l-1.483 7.956a.341.341 0 01-.33.285h-3.6z"
            ></path>
            <path
              fill="#fff"
              d="M32.637 19.206h-3.544c-.323 0-.6.24-.662.57l-3.187 17.098a.726.726 0 00.142.582c.128.162.32.256.52.256h3.544c.323 0 .601-.239.663-.57l1.719-9.225h2.275c1.054 0 1.275.234 1.284.244.063.072.147.404.009 1.148l-1.41 7.565a.726.726 0 00.142.582c.128.162.32.256.52.256h3.6c.323 0 .601-.239.663-.57l1.482-7.956c.348-1.868.05-3.269-.884-4.165-.892-.854-2.386-1.269-4.567-1.269h-2.338l.691-3.707a.726.726 0 00-.141-.582.664.664 0 00-.521-.257zm0 .704l-.847 4.546h3.156c1.986 0 3.356.362 4.11 1.084.754.723.98 1.893.679 3.512l-1.483 7.956h-3.6l1.41-7.565c.16-.86.102-1.447-.177-1.76-.278-.313-.871-.47-1.778-.47h-2.832l-1.825 9.795h-3.543l3.186-17.098h3.544z"
            ></path>
            <path
              fill="#000"
              d="M45.673 33.958c1.357 0 2.37-.26 3.01-.775.635-.51 1.073-1.392 1.303-2.622.214-1.15.132-1.954-.243-2.387-.383-.443-1.211-.668-2.462-.668h-2.17l-1.201 6.452h1.763zm-7.094 7.948a.332.332 0 01-.26-.128.363.363 0 01-.071-.29l3.186-17.1a.342.342 0 01.332-.284h6.867c2.159 0 3.765.611 4.775 1.817 1.015 1.212 1.329 2.906.932 5.035a8.266 8.266 0 01-.826 2.394 7.969 7.969 0 01-1.52 1.986c-.744.727-1.587 1.254-2.501 1.563-.9.306-2.056.461-3.436.461h-2.781l-.794 4.261a.342.342 0 01-.332.285H38.58z"
            ></path>
            <path
              fill="#fff"
              d="M45.39 27.858h1.89c1.51 0 2.035.346 2.213.552.296.341.352 1.062.161 2.084-.213 1.143-.609 1.955-1.176 2.41-.58.466-1.525.702-2.805.702h-1.354l1.071-5.748zm3.243-4.106h-6.867c-.323 0-.601.24-.663.57L37.917 41.42a.726.726 0 00.141.582c.129.162.32.256.521.256h3.572c.322 0 .6-.239.662-.57l.74-3.976h2.504c1.415 0 2.606-.16 3.54-.478.961-.326 1.846-.878 2.628-1.64a8.322 8.322 0 001.583-2.072 8.624 8.624 0 00.863-2.499c.418-2.241.078-4.036-1.009-5.334-1.077-1.285-2.769-1.937-5.029-1.937zM43.5 34.31h2.173c1.44 0 2.512-.283 3.217-.848.705-.566 1.18-1.51 1.427-2.834.236-1.271.129-2.168-.323-2.69-.452-.522-1.356-.784-2.713-.784h-2.447L43.5 34.31zm5.133-9.854c2.066 0 3.573.566 4.52 1.697.948 1.13 1.233 2.71.855 4.736a7.871 7.871 0 01-.789 2.29 7.592 7.592 0 01-1.453 1.898c-.713.696-1.506 1.192-2.377 1.488-.872.296-1.983.443-3.332.443h-3.06l-.846 4.546h-3.572l3.187-17.098h6.867z"
            ></path>
          </g>
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_7_128"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(40.8388 0 0 42.5905 19.573 20.937)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AEB2D5"></stop>
            <stop offset="0.3" stopColor="#AEB2D5"></stop>
            <stop offset="0.75" stopColor="#484C89"></stop>
            <stop offset="1" stopColor="#484C89"></stop>
          </radialGradient>
          <clipPath id="clip0_7_128">
            <path
              fill="#fff"
              d="M0 0H64V36H0z"
              transform="translate(0 14)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    )
  }