import { SVGProps } from "react";

export function TypeScriptIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 64 64"
    >
      <g clipPath="url(#clip0_5_10)">
        <g clipPath="url(#clip1_5_10)">
          <path
            fill="#3178C6"
            d="M57.75 0H6.25A6.25 6.25 0 000 6.25v51.5A6.25 6.25 0 006.25 64h51.5A6.25 6.25 0 0064 57.75V6.25A6.25 6.25 0 0057.75 0z"
          ></path>
          <path
            fill="#3178C6"
            d="M57.75 0H6.25A6.25 6.25 0 000 6.25v51.5A6.25 6.25 0 006.25 64h51.5A6.25 6.25 0 0064 57.75V6.25A6.25 6.25 0 0057.75 0z"
          ></path>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M39.617 50.928v6.258c1.018.521 2.22.912 3.61 1.173a23.78 23.78 0 004.392.391c1.5 0 2.924-.143 4.274-.43 1.35-.287 2.534-.76 3.55-1.418a7.224 7.224 0 002.417-2.581c.593-1.063.89-2.376.89-3.94 0-1.135-.17-2.129-.509-2.983a6.97 6.97 0 00-1.467-2.278 10.742 10.742 0 00-2.299-1.79 24.62 24.62 0 00-3.022-1.495 38.048 38.048 0 01-2.21-.988 11.23 11.23 0 01-1.663-.977c-.457-.333-.81-.685-1.057-1.056a2.223 2.223 0 01-.372-1.262c0-.43.111-.818.333-1.163.222-.346.535-.642.939-.89.404-.248.9-.44 1.487-.577a8.63 8.63 0 011.956-.205 13.07 13.07 0 013.404.479c.587.163 1.158.368 1.712.616a9.44 9.44 0 011.535.86v-5.846a15.277 15.277 0 00-3.12-.812c-1.128-.176-2.422-.264-3.883-.264-1.487 0-2.895.16-4.226.48-1.33.319-2.5.817-3.511 1.495a7.474 7.474 0 00-2.397 2.591c-.587 1.05-.88 2.304-.88 3.764 0 1.865.538 3.455 1.614 4.772 1.076 1.317 2.71 2.431 4.9 3.344.861.352 1.663.697 2.407 1.036.743.34 1.386.691 1.927 1.056.54.365.968.763 1.281 1.193.313.43.47.919.47 1.467 0 .404-.098.779-.294 1.124-.196.345-.492.645-.89.9-.398.254-.893.453-1.487.596-.593.143-1.288.215-2.083.215-1.357 0-2.7-.238-4.03-.714a12.024 12.024 0 01-3.698-2.141zM29.097 35.51h8.028v-5.135H14.75v5.135h7.988v22.865h6.36V35.51z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5_10">
          <path fill="#fff" d="M0 0H64V64H0z"></path>
        </clipPath>
        <clipPath id="clip1_5_10">
          <path fill="#fff" d="M0 0H64V64H0z"></path>
        </clipPath>
      </defs>
    </svg>
    )
  }