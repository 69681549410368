import React from 'react';
import './Navbar.scss';

function Navbar() {
    const handleClickScroll = (id : string) => {
        const element = document.getElementById(id);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="Navbar">
            <a className="name" onClick={() => {handleClickScroll("profil")}}>
                Benjamin Talpa
            </a>
            <div className="nav">
                <a onClick={() => {handleClickScroll("skills")}}>Skills</a>
                <a onClick={() => {handleClickScroll("projects")}}>Projects</a>
                <a onClick={() => {handleClickScroll("repositories")}}>Repositories</a>
            </div>
        </div>
    );
}

export default Navbar;