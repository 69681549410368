import React from 'react';
import './Socials.scss';
import { GithubSocial } from '../images/socials/GithubSocial';
import { Linkedin } from '../images/socials/Linkedin';
import { Mail } from '../images/socials/Mail';

function Socials() {
    return (
        <div className="Socials">
            <a className="social" href="https://github.com/Mbk10201" target="_blank">
                <GithubSocial />
            </a>
            <a className="social" href="https://www.linkedin.com/in/talpa-benjamin/" target="_blank">
                <Linkedin />
            </a>
            <a className="social" href="mailto:benitalpa1020@gmail.com" target="_blank">
                <Mail />
            </a>
        </div>
    );
}

export default Socials;