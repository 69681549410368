import { SVGProps } from "react";

export function Mail(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_13_4)">
          <path
            fill="#3273EB"
            d="M32 58.667c-3.689 0-7.155-.7-10.4-2.102-3.244-1.4-6.067-3.3-8.467-5.698-2.4-2.4-4.3-5.223-5.698-8.467-1.4-3.244-2.1-6.711-2.102-10.4 0-3.689.7-7.156 2.102-10.4 1.4-3.244 3.3-6.067 5.698-8.467 2.4-2.4 5.223-4.3 8.467-5.698 3.244-1.4 6.711-2.1 10.4-2.102 3.689 0 7.156.7 10.4 2.102 3.245 1.4 6.067 3.3 8.467 5.698 2.4 2.4 4.3 5.223 5.701 8.467 1.4 3.244 2.1 6.711 2.099 10.4v3.867c0 2.622-.9 4.856-2.699 6.701-1.8 1.845-4.01 2.767-6.635 2.765a9.972 9.972 0 01-4.4-1 9.395 9.395 0 01-3.466-2.866c-1.29 1.289-2.745 2.256-4.368 2.901a13.638 13.638 0 01-5.099.965c-3.689 0-6.834-1.3-9.435-3.901-2.6-2.6-3.9-5.745-3.898-9.432 0-3.689 1.3-6.834 3.901-9.435 2.6-2.6 5.745-3.9 9.432-3.898 3.689 0 6.834 1.3 9.435 3.901 2.6 2.6 3.9 5.745 3.898 9.432v3.867c0 1.155.378 2.133 1.134 2.933.755.8 1.71 1.2 2.866 1.2 1.156 0 2.111-.4 2.867-1.2.756-.8 1.133-1.778 1.133-2.933V32c0-5.956-2.066-11-6.2-15.133-4.133-4.134-9.177-6.2-15.133-6.2-5.956 0-11 2.066-15.133 6.2-4.134 4.133-6.2 9.177-6.2 15.133 0 5.956 2.066 11 6.2 15.133 4.133 4.134 9.177 6.2 15.133 6.2h13.333v5.334H32zM32 40c2.222 0 4.111-.778 5.667-2.333C39.222 36.11 40 34.222 40 32s-.778-4.111-2.333-5.667C36.11 24.778 34.222 24 32 24s-4.111.778-5.667 2.333C24.778 27.89 24 29.778 24 32s.778 4.111 2.333 5.667C27.89 39.222 29.778 40 32 40z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_13_4">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }