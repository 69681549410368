import { SVGProps } from "react";

export function AngularIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_9_210)">
          <path
            fill="#DD0031"
            d="M32 7.68l-23.834 8.5 3.636 31.513L32 58.88l20.198-11.187 3.636-31.514L32 7.68z"
          ></path>
          <path
            fill="#C3002F"
            d="M32 7.68v5.683-.025V58.88l20.198-11.187 3.636-31.514L32 7.68z"
          ></path>
          <path
            fill="#fff"
            d="M32 13.338L17.1 46.746h5.556l2.995-7.476h12.647l2.995 7.476h5.555L32 13.338zm4.352 21.324h-8.704L32 24.192l4.352 10.47z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_9_210">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }