import { SVGProps } from "react";

export function SourceEngineIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_7_111)">
          <path
            fill="#666"
            d="M2.271 34.73c.017 1.065.892 1.494 1.834 1.494.69 0 1.565-.275 1.565-1.133 0-.738-.993-.996-2.71-1.374-1.379-.309-2.758-.806-2.758-2.369 0-2.265 1.918-2.85 3.785-2.85 1.902 0 3.651.653 3.837 2.833H5.552c-.067-.944-.774-1.201-1.631-1.201-.54 0-1.33.102-1.33.823 0 .876 1.346.996 2.709 1.322 1.396.326 2.76.841 2.76 2.49 0 2.334-1.986 3.09-3.971 3.09-2.02 0-3.987-.773-4.089-3.125h2.271zM13.006 28.499c2.742 0 4.509 1.854 4.509 4.686 0 2.816-1.767 4.67-4.51 4.67-2.725 0-4.492-1.854-4.492-4.67 0-2.832 1.767-4.686 4.493-4.686zm0 7.519c1.631 0 2.12-1.425 2.12-2.833 0-1.425-.489-2.85-2.12-2.85-1.616 0-2.104 1.425-2.104 2.85 0 1.408.488 2.833 2.104 2.833zM26.25 37.614h-2.27v-1.236h-.05c-.607.996-1.65 1.477-2.66 1.477-2.54 0-3.18-1.46-3.18-3.657v-5.459h2.39v5.013c0 1.46.42 2.18 1.531 2.18 1.296 0 1.851-.738 1.851-2.54v-4.653h2.389v8.875zM26.975 28.74h2.27v1.647h.034c.438-1.116 1.616-1.888 2.777-1.888.168 0 .37.034.521.085v2.267a4.248 4.248 0 00-.857-.086c-1.75 0-2.356 1.287-2.356 2.85v3.999h-2.39v-8.875zM38.466 31.863c-.152-.995-.774-1.527-1.766-1.527-1.532 0-2.037 1.58-2.037 2.884 0 1.27.489 2.798 1.986 2.798 1.11 0 1.75-.72 1.901-1.802h2.305c-.303 2.351-1.901 3.64-4.19 3.64-2.624 0-4.39-1.89-4.39-4.55 0-2.764 1.615-4.807 4.441-4.807 2.053 0 3.937 1.099 4.089 3.364h-2.339zM43.496 33.769c.068 1.545.808 2.249 2.137 2.249.96 0 1.733-.6 1.884-1.15h2.104c-.674 2.094-2.104 2.987-4.072 2.987-2.742 0-4.442-1.923-4.442-4.67 0-2.66 1.8-4.686 4.442-4.686 2.961 0 4.391 2.54 4.223 5.27h-6.276zm3.887-1.545c-.219-1.236-.74-1.888-1.902-1.888-1.514 0-1.951 1.202-1.985 1.888h3.887z"
          ></path>
          <path
            fill="#F79A10"
            d="M57.984 22.249c-.546-.229-1.05-.256-1.665-.248l-10.4.058a10.918 10.918 0 00-6.09 1.5l.002.002c-.327.181-.55.536-.55.943 0 .592.472 1.073 1.052 1.073.303 0 .575-.131.767-.34 2.287-1.25 5.082-1.491 7.663-.41 4.585 1.92 6.783 7.29 4.901 11.967a9.124 9.124 0 01-2.168 3.203l.008.011a1.077 1.077 0 00-.523.928c0 .592.472 1.073 1.053 1.073.373 0 .701-.2.888-.501a11.214 11.214 0 002.641-3.92c.043-.104 4.304-10.694 4.315-10.722.727-1.807-.122-3.875-1.894-4.617z"
          ></path>
          <path
            fill="#000"
            d="M62.19 24.418v1.153h-.197v-1.153h-.36v-.181h.918v.18h-.36zM63.73 25.57v-.891l-.301.65h-.147l-.308-.65v.892h-.199v-1.334h.199l.382.83.375-.83h.198v1.334h-.198z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_7_111">
            <path
              fill="#fff"
              d="M0 0H64V20H0z"
              transform="translate(0 22)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    )
  }