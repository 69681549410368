import { SVGProps } from "react";

export function PostmanIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_18_34)">
          <g clipPath="url(#clip1_18_34)">
            <path
              fill="#FF6C37"
              d="M63.738 36.063C65.978 18.53 53.596 2.501 36.095.262 18.595-1.978 2.501 10.404.262 27.905c-2.24 17.5 10.142 33.562 27.643 35.833 17.533 2.24 33.562-10.142 35.833-27.675z"
            ></path>
            <path
              fill="#fff"
              d="M43.55 20.546L30.048 34.048l-3.807-3.808c13.278-13.277 14.59-12.125 17.309-9.694z"
            ></path>
            <path
              fill="#FF6C37"
              d="M30.048 34.368a.29.29 0 01-.224-.096l-3.839-3.808a.31.31 0 010-.448c13.502-13.501 14.91-12.221 17.757-9.662a.29.29 0 01.096.224.29.29 0 01-.096.224L30.24 34.272c-.032.064-.128.096-.192.096zM26.69 30.24l3.36 3.36L43.07 20.578c-2.368-2.112-3.967-2.751-16.381 9.662z"
            ></path>
            <path
              fill="#fff"
              d="M33.92 37.919l-3.68-3.68 13.502-13.501c3.615 3.647-1.792 9.566-9.822 17.18z"
            ></path>
            <path
              fill="#FF6C37"
              d="M33.92 38.239a.29.29 0 01-.224-.096l-3.68-3.68c-.064-.063-.064-.127-.064-.223a.29.29 0 01.096-.224L43.55 20.514a.31.31 0 01.448 0 3.895 3.895 0 011.248 2.976c-.064 3.55-4.096 8.062-11.07 14.653a.51.51 0 01-.256.096zm-3.232-4c2.048 2.08 2.912 2.912 3.232 3.232 5.375-5.12 10.59-10.366 10.622-13.981.032-.832-.288-1.664-.832-2.304L30.688 34.24z"
            ></path>
            <path
              fill="#fff"
              d="M26.305 30.336l2.72 2.72c.064.064.064.128 0 .192-.032.032-.032.032-.064.032l-5.631 1.216a.56.56 0 01-.608-.448.473.473 0 01.128-.416l3.263-3.264c.064-.064.16-.096.192-.032z"
            ></path>
            <path
              fill="#FF6C37"
              d="M23.234 34.816c-.48 0-.832-.384-.832-.864 0-.224.096-.448.256-.608l3.263-3.264a.487.487 0 01.64 0l2.72 2.72c.192.16.192.448 0 .64a.516.516 0 01-.224.128l-5.631 1.215c-.064 0-.128.032-.192.032zm2.975-4.128l-3.135 3.136c-.064.064-.096.16-.032.256.032.096.128.128.224.096l5.279-1.152-2.336-2.336z"
            ></path>
            <path
              fill="#fff"
              d="M50.685 13.06c-2.048-1.984-5.343-1.92-7.327.16a5.167 5.167 0 00.16 7.326 5.17 5.17 0 006.27.704l-3.646-3.647 4.543-4.544z"
            ></path>
            <path
              fill="#FF6C37"
              d="M47.101 22.306a5.503 5.503 0 110-11.006c1.408 0 2.784.544 3.808 1.535a.29.29 0 01.096.224.29.29 0 01-.096.224l-4.32 4.32 3.392 3.391a.31.31 0 010 .448l-.064.064c-.832.512-1.824.8-2.816.8zm0-10.334c-2.687 0-4.863 2.175-4.83 4.863 0 2.687 2.175 4.863 4.862 4.83a4.6 4.6 0 002.144-.511l-3.36-3.327a.29.29 0 01-.096-.224.29.29 0 01.096-.224l4.288-4.288c-.864-.736-1.952-1.12-3.104-1.12z"
            ></path>
            <path
              fill="#fff"
              d="M50.78 13.155l-.063-.064-4.575 4.512 3.615 3.615c.352-.224.704-.48.992-.768a5.127 5.127 0 00.032-7.295z"
            ></path>
            <path
              fill="#FF6C37"
              d="M49.789 21.57a.29.29 0 01-.224-.096l-3.648-3.647a.29.29 0 01-.096-.224.29.29 0 01.096-.224l4.544-4.544a.31.31 0 01.448 0l.096.064c2.143 2.144 2.143 5.6.032 7.775-.32.32-.672.608-1.056.832-.096.032-.16.064-.192.064zm-3.2-3.967l3.232 3.231c.256-.16.512-.384.704-.576 1.823-1.824 1.92-4.799.16-6.719l-4.096 4.064z"
            ></path>
            <path
              fill="#fff"
              d="M44.094 21.122a1.97 1.97 0 00-2.784 0L29.248 33.184l2.016 2.015L44.03 24.001c.832-.703.896-1.951.192-2.783a.44.44 0 01-.128-.096z"
            ></path>
            <path
              fill="#FF6C37"
              d="M31.232 35.52a.29.29 0 01-.224-.097l-2.015-2.015a.31.31 0 010-.448l12.061-12.062a2.279 2.279 0 013.232 0 2.279 2.279 0 010 3.231l-.096.096-12.766 11.198c-.032.064-.096.096-.192.096zm-1.536-2.336l1.568 1.568 12.542-11.007c.704-.575.768-1.631.192-2.335-.576-.704-1.632-.768-2.336-.192-.032.032-.064.064-.128.096l-11.838 11.87z"
            ></path>
            <path
              fill="#fff"
              d="M20.002 46.91c-.128.063-.192.191-.16.32l.544 2.303c.128.32-.064.704-.416.8a.642.642 0 01-.704-.192l-3.52-3.488 11.487-11.486 3.967.064 2.688 2.688c-.64.544-4.511 4.287-13.886 8.99z"
            ></path>
            <path
              fill="#FF6C37"
              d="M19.746 50.653c-.256 0-.512-.096-.672-.288l-3.487-3.488a.29.29 0 01-.096-.224.29.29 0 01.096-.224l11.486-11.486a.346.346 0 01.224-.096l3.967.064a.29.29 0 01.224.096l2.688 2.688c.064.064.096.16.096.256s-.032.16-.128.224l-.224.192c-3.392 2.975-7.999 5.95-13.726 8.798l.544 2.272a1.01 1.01 0 01-.48 1.088 1.098 1.098 0 01-.512.128zm-3.52-4l3.296 3.264c.096.16.288.224.448.128.16-.096.224-.288.128-.448l-.544-2.304a.583.583 0 01.32-.672c5.663-2.847 10.238-5.79 13.598-8.702l-2.368-2.368-3.68-.064-11.197 11.166z"
            ></path>
            <path
              fill="#fff"
              d="M13.027 49.405l2.752-2.752 4.095 4.096-6.527-.448a.51.51 0 01-.448-.576c0-.128.032-.256.128-.32z"
            ></path>
            <path
              fill="#FF6C37"
              d="M19.874 51.037l-6.559-.448c-.48-.032-.8-.448-.768-.928.032-.192.096-.384.256-.512l2.752-2.752a.31.31 0 01.448 0l4.095 4.096c.096.096.128.224.064.352-.064.128-.16.192-.288.192zM15.78 47.1L13.25 49.63c-.096.064-.096.224 0 .288.032.032.064.064.128.064l5.663.384L15.78 47.1zM26.113 36.64a.32.32 0 01-.32-.32.29.29 0 01.096-.225l3.104-3.103a.31.31 0 01.448 0l2.015 2.016c.096.095.128.191.096.32a.366.366 0 01-.256.223l-5.119 1.088h-.064zm3.104-2.976l-2.112 2.111 3.455-.736-1.343-1.375z"
            ></path>
            <path
              fill="#fff"
              d="M31.2 35.231L27.68 36a.477.477 0 01-.447-.8l1.951-1.951L31.2 35.23z"
            ></path>
            <path
              fill="#FF6C37"
              d="M27.617 36.32a.792.792 0 01-.8-.8.92.92 0 01.224-.577l1.952-1.951a.31.31 0 01.448 0l2.015 2.016c.096.095.128.191.096.32a.366.366 0 01-.256.223l-3.52.768h-.16zm1.6-2.656l-1.728 1.727c-.064.064-.064.128-.032.192s.096.096.192.096l2.943-.64-1.375-1.375zM50.813 16.227c-.064-.192-.288-.288-.48-.224-.192.064-.288.288-.224.48 0 .032.032.064.032.096.192.384.128.864-.128 1.216a.389.389 0 00.032.511.36.36 0 00.512-.064c.48-.607.576-1.375.256-2.015z"
            ></path>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_18_34">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
          <clipPath id="clip1_18_34">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }