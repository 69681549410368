import { SVGProps } from "react";

export function BlenderIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <path
          fill="#fff"
          d="M24.149 35.133c.227-4.03 2.21-7.591 5.215-10.108 2.94-2.474 6.89-3.988 11.224-3.988 4.314 0 8.263 1.512 11.203 3.988 3.005 2.517 4.98 6.078 5.215 10.108.228 4.138-1.448 7.998-4.378 10.855-3.004 2.9-7.254 4.735-12.04 4.735s-9.056-1.822-12.04-4.735c-2.94-2.857-4.614-6.696-4.378-10.833l-.021-.022z"
        ></path>
        <path
          fill="#265787"
          d="M33.14 34.64c.118-2.071 1.137-3.902 2.68-5.195 1.512-1.27 3.541-2.049 5.769-2.049 2.217 0 4.246.777 5.757 2.05 1.544 1.292 2.559 3.123 2.68 5.194.117 2.126-.745 4.11-2.25 5.578-1.544 1.49-3.728 2.433-6.187 2.433-2.46 0-4.654-.936-6.187-2.433-1.511-1.469-2.371-3.441-2.25-5.567l-.011-.011z"
        ></path>
        <path
          fill="#EA7600"
          d="M19.906 39.023c.015.811.275 2.39.665 3.617.82 2.608 2.217 5.02 4.147 7.134 1.985 2.181 4.434 3.935 7.257 5.184 2.967 1.304 6.187 1.973 9.529 1.973 3.342-.005 6.562-.684 9.529-2.006 2.823-1.26 5.272-3.014 7.257-5.206 1.941-2.136 3.33-4.547 4.147-7.156a17.98 17.98 0 00.78-3.989 18.12 18.12 0 00-.13-3.967 18.308 18.308 0 00-2.647-7.19c-1.257-2.027-2.889-3.79-4.83-5.281l.004-.004-19.52-14.904c-.018-.013-.033-.027-.052-.04-1.279-.977-3.44-.974-4.841.006-1.423.99-1.588 2.63-.32 3.66l-.006.006 8.15 6.586-24.814.027h-.033c-2.052.002-4.026 1.337-4.412 3.035-.401 1.72.993 3.156 3.132 3.156l-.003.008 12.573-.024L2.969 40.744c-.029.02-.06.043-.086.064-2.117 1.61-2.801 4.296-1.467 5.983 1.357 1.72 4.235 1.732 6.375.01l12.242-9.973s-.179 1.348-.164 2.148l.037.047zm31.543 4.505c-2.526 2.553-6.066 4.01-9.882 4.01-3.827.007-7.367-1.424-9.893-3.977-1.235-1.25-2.14-2.675-2.702-4.198a10.845 10.845 0 01-.62-4.69 10.954 10.954 0 011.357-4.417 12.371 12.371 0 013-3.55c2.459-1.984 5.58-3.069 8.845-3.069 3.275-.004 6.396 1.062 8.856 3.047a12.278 12.278 0 013 3.54 10.886 10.886 0 011.356 4.416 10.88 10.88 0 01-.622 4.69c-.56 1.535-1.467 2.96-2.702 4.209l.007-.011z"
        ></path>
      </svg>
    )
  }