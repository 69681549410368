import React from 'react';
import './GoTop.scss';
import { ArrowIcon } from '../images/ArrowIcon';

function GoTop() {
    const NavigateTop = () =>
    {
        const element = document.getElementById("profil");
        console.log(element);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className="GoTop" onClick={NavigateTop}>
          <ArrowIcon></ArrowIcon>
        </div>
    );
}

export default GoTop;