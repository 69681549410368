import { SVGProps } from "react";

export function DockerIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_7_88)">
          <path
            fill="#000"
            d="M37 28.25h5.25zm-6.25 0H36zm-6.125 0h5.25zm-6.125 0h5.125zm-6.25 0h5.25zm6.25-5.75h5.125zm6.125 0h5.25zm6.125 0H36zm0-5.75H36z"
          ></path>
          <path
            stroke="#066DA5"
            strokeWidth="4.75"
            d="M37 28.25h5.25m-11.5 0H36m-11.375 0h5.25m-11.375 0h5.125m-11.375 0h5.25m1-5.75h5.125m1 0h5.25m.875 0H36m-5.25-5.75H36"
          ></path>
          <path
            fill="#066DA5"
            d="M59 28.5s-2.25-2.125-6.875-1.375c-.5-3.625-4.375-5.75-4.375-5.75s-3.625 4.375-1 9.25c-.75.375-2 .875-3.875.875H8.5c-.625 2.375-.625 18.125 16.625 18.125 12.375 0 21.625-5.75 26-16.25 6.5.5 7.875-4.875 7.875-4.875z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_7_88">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }