import { SVGProps } from "react";

export function VBNetIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_17_19)">
          <g clipPath="url(#clip1_17_19)">
            <path
              fill="#004E8C"
              d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32z"
            ></path>
            <path
              fill="#fff"
              d="M33.29 19.4L24 44.6h-3.29l-9.14-25.2h3.29l7 20c.241.725.412 1.472.51 2.23h.07c.105-.778.293-1.542.56-2.28l7.12-20 3.17.05zM36.92 44.6V19.4h7.17a7.84 7.84 0 015.18 1.6 5.17 5.17 0 011.92 4.17A6.13 6.13 0 0150 28.89a6.26 6.26 0 01-3.2 2.25v.07a6.41 6.41 0 014.08 1.92 5.92 5.92 0 011.53 4.23 6.591 6.591 0 01-2.32 5.24 8.64 8.64 0 01-5.85 2h-7.32zm3-22.54v8.14h3a5.74 5.74 0 003.79-1.2 4.071 4.071 0 001.39-3.3c0-2.447-1.61-3.67-4.83-3.67l-3.35.03zm0 10.79v9.07h4a6 6 0 004-1.23 4.21 4.21 0 001.43-3.37c0-2.973-2.027-4.46-6.08-4.46l-3.35-.01z"
            ></path>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_17_19">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
          <clipPath id="clip1_17_19">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }