import { SVGProps } from "react";

export function GithubSocial(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 64 64"
      >
        <path
          fill="#3273EB"
          fillRule="evenodd"
          d="M32.026 0C14.316 0 0 14.437 0 32.298c0 14.278 9.173 26.363 21.899 30.64 1.59.322 2.174-.694 2.174-1.55 0-.748-.053-3.315-.053-5.989-8.909 1.925-10.764-3.85-10.764-3.85-1.432-3.743-3.553-4.705-3.553-4.705-2.916-1.979.212-1.979.212-1.979 3.235.214 4.932 3.315 4.932 3.315 2.863 4.92 7.476 3.53 9.332 2.674.265-2.086 1.114-3.53 2.015-4.331-7.106-.75-14.581-3.53-14.581-15.936 0-3.53 1.271-6.417 3.287-8.662-.319-.802-1.432-4.118.318-8.557 0 0 2.704-.855 8.802 3.316a30.76 30.76 0 018.006-1.07c2.705 0 5.461.375 8.007 1.07 6.098-4.171 8.802-3.316 8.802-3.316 1.75 4.439.636 7.755.318 8.557 2.068 2.245 3.288 5.133 3.288 8.662 0 12.406-7.477 15.133-14.635 15.936 1.167 1.016 2.174 2.94 2.174 5.989 0 4.33-.053 7.807-.053 8.876 0 .856.584 1.872 2.174 1.551C54.827 58.661 64 46.576 64 32.3 64.052 14.436 49.683 0 32.026 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    )
  }