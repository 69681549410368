import { SVGProps } from "react";

export function AdobeIcon(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 64 64"
      >
        <g clipPath="url(#clip0_17_11)">
          <g clipPath="url(#clip1_17_11)">
            <path
              fill="#ED2224"
              d="M54.4 0H9.6A9.6 9.6 0 000 9.6v44.8A9.6 9.6 0 009.6 64h44.8a9.6 9.6 0 009.6-9.6V9.6A9.6 9.6 0 0054.4 0z"
            ></path>
            <path
              fill="#fff"
              d="M37 15h14.25v34L37 15zm-10 0H12.75v34L27 15zm4.875 12.375l-6 14.75h6.5L35.25 49H41l-9.125-21.625z"
            ></path>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_17_11">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
          <clipPath id="clip1_17_11">
            <path fill="#fff" d="M0 0H64V64H0z"></path>
          </clipPath>
        </defs>
      </svg>
    )
  }