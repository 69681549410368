import React from 'react';
import './Repositories.scss';
import { GithubSocial } from '../images/socials/GithubSocial';
import { Linkedin } from '../images/socials/Linkedin';
import { Mail } from '../images/socials/Mail';

function Repositories() {
    return (
        <div className="Repositories" id="repositories">
            <div className="title">Repositories</div>
            <a className="repo" href="https://github.com/Mbk10201/-SBOX-Discord-API-" target="_blank">
                <div className="repo__name">S&Box - Discord API <span>2022 - Current</span></div>
                <hr/>
                <div className="repo__technology">Technologies: C#</div>
                <div className="repo__description">A S&box Library made by hand to allow to fire / share events or any kind of data to a discord server by token or webhook.</div>
            </a>
            <a className="repo" href="https://github.com/Mbk10201/roleplay_api" target="_blank">
                <div className="repo__name">S&Box - Roleplay Library <span>2022 - Current</span></div>
                <hr/>
                <div className="repo__technology">Technologies: C#, Blender, Figma</div>
                <div className="repo__description">Roleplay library </div>
            </a>
            <a className="repo" href="https://github.com/Mbk10201/Discord_loggerv2" target="_blank">
                <div className="repo__name">Discord Logger for CS:GO <span>2022</span></div>
                <hr/>
                <div className="repo__technology">Technologies: SourcePawn</div>
                <div className="repo__description">I've made a discord plugin to allow any source one engine to be able to communicated with a discord server through a webhook.</div>
            </a>
            <a className="repo" href="https://github.com/Mbk10201/Roleplay-CSGO--core-" target="_blank">
                <div className="repo__name">Roleplay Gamemode for CS:GO <span>2021 - 2022 </span></div>
                <hr/>
                <div className="repo__technology">Technologies: SourcePawn, C++, .NET 6, Blender</div>
                <div className="repo__description">I've designed and programmed a roleplay gamemode for CS:GO, the project was archieved in 1 year.</div>
            </a>
        </div>
    );
}

export default Repositories;